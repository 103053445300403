import { TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import { InfoCircleIcon, SparklesIcon } from 'src/mui/_icons';

import { Tooltip } from 'src/mui';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import { useTabPanelsContext } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider';
import { PreviewTab } from 'src/features/bills-to-pay/BillContent/TabPanels/TabPanelsProvider/types';

import styles from './PreviewTabs.module.scss';
import Label from './Label';

function a11yProps(index: number) {
  return {
    id: `bill-tabs-${index}`,
    'aria-controls': `bill-tabpanel-${index}`,
  };
}

export default function PreviewTabs() {
  const { isEnabled: isEnabledSuggestionsTab } = useExternalFeatureFlag(
    Feature.SUGGESTIONS_TAB
  );

  const { isEnabled: isEnabledOCRAISuggestions } = useExternalFeatureFlag(
    Feature.OCR_AI_SUGGESTIONS
  );

  const { setSelectedPreviewTab } = useTabPanelsContext();

  return (
    <TabList
      textColor="secondary"
      indicatorColor="secondary"
      onChange={(_, value) => setSelectedPreviewTab(value)}
      className={styles.tabs}
    >
      {isEnabledSuggestionsTab && (
        <Tab
          label={
            isEnabledOCRAISuggestions ? (
              <Label
                title="Sugestões de Preenchimento"
                icon={
                  <Tooltip title="As sugestões de preenchimento são geradas pela FinBot a partir das informações e anexos recebidos na caixa de entrada ou inseridos no lançamento.">
                    <SparklesIcon
                      className={styles.suggestionsIcon}
                      aria-label="Sugestões pela FinBot"
                    />
                  </Tooltip>
                }
              />
            ) : (
              <Label
                title="Sugestões de Preenchimento"
                subtitle="BETA"
                icon={
                  <Tooltip
                    title={
                      <>
                        <p>BETA - Em desenvolvimento</p>
                        <p>
                          As sugestões de preenchimento são geradas a partir das
                          informações e anexos recebidos na caixa de entrada ou
                          inseridos no lançamento.
                        </p>
                      </>
                    }
                  >
                    <InfoCircleIcon className={styles.infoIcon} />
                  </Tooltip>
                }
              />
            )
          }
          value={PreviewTab.SUGGESTIONS}
          {...a11yProps(2)}
        />
      )}

      <Tab
        label={<Label title="Visualização de Anexos" />}
        value={PreviewTab.ATTACHMENTS}
        {...a11yProps(3)}
      />
    </TabList>
  );
}
