import type { ReactNode } from 'react';

import { Chip, Typography } from 'src/design-system/components';

import styles from './Label.module.scss';

type Props = {
  title: string;
  subtitle?: string;
  icon?: ReactNode;
  badgeValue?: number;
};

export default function Label({ title, subtitle, icon, badgeValue }: Props) {
  return (
    <>
      <Typography variant="overline" className={styles.subtitle}>
        {subtitle}
      </Typography>
      <Typography className={styles.title}>
        {title}
        {badgeValue !== undefined && (
          <Chip label={badgeValue} color="secondary" size="xsmall" />
        )}
        {icon}
      </Typography>
    </>
  );
}
