import { useState } from 'react';

import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import type { FormInstance } from 'antd';
import { Form } from 'antd';
import type { NamePath } from 'antd/lib/form/interface';
import { PlusOutlined } from '@ant-design/icons';

import { snackbar } from 'src/mui';

import { DrawerFooter } from 'src/ui';

import { useCreateSplits } from 'src/libs/finbits/Management/FinancialEntries/Splits';
import type { ApiError } from 'src/libs/finbits/client';
import type {
  SplitAttributes,
  SplitParams,
} from 'src/libs/finbits/Management/FinancialEntries/Splits/types';
import type { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import type { FormValues } from 'src/features/EntryForm/types';
import { classificationToFormValues } from 'src/features/EntryForm/formValues';

import BaseFields from './BaseFields';
import SplitItem from './SplitItem';
import SplitDrawerButtonTrigger from './SplitDrawerButtonTrigger';
import { AmountCalculationResult } from './AmountCalculator';
import type { SplitFormValues } from './types';
import styles from './SplitForm.module.less';

type InitialValues = {
  originDescription?: string | null;
  accountId?: string | null;
  date?: Date | null;
  amount?: number | null;
  update: FormValues[];
  create: FormValues[];
  delete: string[];
};

type Props = {
  companyId?: string;
  initialValues?: InitialValues;
  organizationId?: string;
  transactionId: string;
  type?: BalanceType;
  disabled?: boolean;
  onFieldsChange: () => void;
  onClose: () => void;
  scheduledEntryIdsConciliated?: string[];
};

function onConciliateSplitError(
  { response }: ApiError,
  form: FormInstance<SplitFormValues>
) {
  if (
    typeof response?.data.errors === 'string' &&
    response?.data.errors?.includes('Scheduled entry already conciliated')
  ) {
    snackbar({
      variant: 'error',
      message: 'Ops! Parece que este lançamento programado já foi conciliado.',
    });

    const errors = response.data.errors.match(
      /\((?<scheduledEntryId>.+)\).*/
    )?.groups;

    if (errors && 'scheduledEntryId' in errors) {
      const { scheduledEntryId } = errors;

      // set field error
      const values = form.getFieldsValue();

      const createIndexWithError = values.create.findIndex(
        (splitValues) => splitValues.scheduledEntryId === scheduledEntryId
      );
      if (createIndexWithError > -1) {
        form.setFields([
          {
            name: ['create', createIndexWithError, 'scheduledEntryId'],
            errors: ['Conciliação com problema'],
          },
        ]);
      }

      const updateIndexWithError = values.update.findIndex(
        (splitValues) => splitValues.scheduledEntryId === scheduledEntryId
      );
      if (updateIndexWithError > -1) {
        form.setFields([
          {
            name: ['update', updateIndexWithError, 'scheduledEntryId'],
            errors: ['Conciliação com problema'],
          },
        ]);
      }
      //end set field error
    }
  }
}

function getScheduledIds(formInstances?: Array<FormValues>): string[] {
  return (
    formInstances?.map((form: FormValues) => form?.scheduledEntryId ?? '') ?? []
  );
}

function SplitForm({
  organizationId,
  companyId,
  onFieldsChange,
  transactionId,
  type,
  onClose,
  disabled = false,
  initialValues,
  scheduledEntryIdsConciliated = [],
}: Props) {
  const [scheduledIdsConciliated, setScheduledIdsConciliated] = useState(
    scheduledEntryIdsConciliated
  );

  const [scheduledIdsToConciliate, setScheduledIdsToConciliate] = useState([
    ...getScheduledIds(initialValues?.create),
    ...getScheduledIds(initialValues?.update),
  ]);

  const [updateSplitLenght] = useState(initialValues?.update.length ?? 0);

  const [form] = Form.useForm<SplitFormValues>();

  const deleteIds = Form.useWatch('delete', form) ?? [];

  const { createSplits, isLoading: isSaving } = useCreateSplits();

  function onSubmit(params: SplitFormValues) {
    if (!organizationId || !companyId) return;

    createSplits(
      {
        organizationId,
        companyId,
        transactionId,
        params: parseToSubmitParams(params),
      },
      {
        onSuccess: () => {
          onClose();

          snackbar({
            variant: 'success',
            message: 'Alterações salvas com sucesso!',
          });
        },
        onError: (error) => {
          if (error.response?.status === 422) {
            onConciliateSplitError(error, form);
            return;
          }

          return snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao salvar as alterações!',
          });
        },
      }
    );
  }

  function handleUnconciliate(scheduledEntryId?: string | null) {
    const filteredIds = scheduledIdsConciliated.filter(
      (id) => id !== scheduledEntryId
    );

    setScheduledIdsConciliated(filteredIds);
  }

  function handleUpdateFieldFormValue(
    fieldInitialValues: FormValues,
    fieldPath: NamePath
  ) {
    return function (newFormValues?: FormValues) {
      form.setFieldValue(fieldPath, newFormValues);

      if (
        fieldInitialValues.scheduledEntryId !== newFormValues?.scheduledEntryId
      ) {
        const newScheduledIdsToConciliate = scheduledIdsToConciliate.filter(
          (id) => id !== fieldInitialValues.scheduledEntryId
        );

        setScheduledIdsToConciliate(
          compact([
            ...newScheduledIdsToConciliate,
            newFormValues?.scheduledEntryId,
          ])
        );
      }

      form.validateFields();
      onFieldsChange();
    };
  }

  function removeIdFromScheduledToConciliate(
    idToRemove?: FormValues['scheduledEntryId']
  ) {
    if (!idToRemove) return;

    const newScheduledIdsToConciliate = scheduledIdsToConciliate.filter(
      (id) => id !== idToRemove
    );

    setScheduledIdsToConciliate(newScheduledIdsToConciliate);
  }

  function addIdToScheduledToConciliate(
    idToAdd?: FormValues['scheduledEntryId']
  ) {
    if (!idToAdd) return;

    setScheduledIdsToConciliate([...scheduledIdsToConciliate, idToAdd]);
  }

  return (
    <Form
      className={styles.container}
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFieldsChange={onFieldsChange}
      onFinish={onSubmit}
      scrollToFirstError
    >
      <BaseFields
        organizationId={organizationId}
        companyId={companyId}
        originDescription={initialValues?.originDescription}
        totalAmount={initialValues?.amount ?? 0}
      />

      <Form.Item name="delete" hidden>
        <input type="hidden" />
      </Form.Item>

      <Form.List name="update">
        {(fields, { remove }) => (
          <>
            {fields.map((field, index) => {
              const fieldValues = form.getFieldValue(['update', field.name]);

              function handleRemove() {
                form.setFieldValue('delete', [...deleteIds, fieldValues.id]);

                removeIdFromScheduledToConciliate(fieldValues.scheduledEntryId);

                remove(field.name);
              }

              return (
                <SplitItem
                  onFormSubmit={handleUpdateFieldFormValue(fieldValues, [
                    'update',
                    field.name,
                  ])}
                  initialValues={fieldValues}
                  key={field.key}
                  index={index}
                  disabled={disabled}
                  removeSplit={handleRemove}
                  field={field}
                  scheduledIdsSelectedToConciliate={scheduledIdsToConciliate}
                  scheduledIdsConciliated={scheduledIdsConciliated}
                  onUnconciliateSuccess={handleUnconciliate}
                  action="update"
                  shouldCallSubmitOnUnconciliate
                />
              );
            })}
          </>
        )}
      </Form.List>

      <Form.List name="create">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => {
              const fieldValues = form.getFieldValue(['create', field.name]);

              function handleRemove() {
                removeIdFromScheduledToConciliate(fieldValues.scheduledEntryId);

                remove(field.name);
              }

              return (
                <SplitItem
                  onFormSubmit={handleUpdateFieldFormValue(fieldValues, [
                    'create',
                    field.name,
                  ])}
                  initialValues={{
                    date: initialValues?.date,
                    accountId: initialValues?.accountId,
                    ...fieldValues,
                  }}
                  key={field.key}
                  index={index + updateSplitLenght}
                  disabled={disabled}
                  removeSplit={handleRemove}
                  field={field}
                  scheduledIdsSelectedToConciliate={scheduledIdsToConciliate}
                  scheduledIdsConciliated={scheduledIdsConciliated}
                  onUnconciliateSuccess={handleUnconciliate}
                  action="create"
                  shouldCallSubmitOnUnconciliate
                />
              );
            })}

            <SplitDrawerButtonTrigger
              className={styles.button}
              onAdd={(formValues) => {
                add(formValues);

                addIdToScheduledToConciliate(formValues.scheduledEntryId);
              }}
              initialValues={{
                date: initialValues?.date,
                accountId: initialValues?.accountId,
                type: type,
              }}
              scheduledIdsSelectedToConciliate={scheduledIdsToConciliate}
              scheduledIdsConciliated={scheduledIdsConciliated}
              onUnconciliateSuccess={handleUnconciliate}
              icon={<PlusOutlined />}
            >
              Adicionar quebra
            </SplitDrawerButtonTrigger>
          </>
        )}
      </Form.List>

      <DrawerFooter>
        <AmountCalculationResult totalAmount={initialValues?.amount ?? 0} />

        <DrawerFooter.SubmitButton loading={isSaving} disabled={disabled}>
          Salvar
        </DrawerFooter.SubmitButton>
      </DrawerFooter>
    </Form>
  );
}

export default SplitForm;

function getClassificationsFromLabels(labels: FormValues['labels']) {
  return uniqBy(
    labels?.map((label) => ({
      id: label.classificationId,
      name: 'mocked classification',
    })) ?? [],
    'id'
  );
}

function parse(formValues: FormValues): SplitAttributes {
  const classificationsFromLabels = getClassificationsFromLabels(
    formValues?.labels
  );

  return {
    id: formValues.id,
    description: formValues.description,
    amount: formValues.amount,
    categoryId: formValues.categoryId ?? null,
    contactId: formValues.contactId ?? null,
    comments: formValues.comments ?? null,
    relevantDate: formValues.relevantDate?.toISOString() ?? null,
    attachmentsIds: formValues.attachments?.map((a) => a.id),
    scheduledEntryId:
      formValues.scheduledEntryId && formValues.scheduledEntryId !== ''
        ? formValues.scheduledEntryId
        : null,
    notaFiscalId: Boolean(formValues.notaFiscalId)
      ? formValues.notaFiscalId
      : undefined,
    notaFiscalNumber: Boolean(formValues.notaFiscalNumber)
      ? formValues.notaFiscalNumber
      : undefined,
    notaFiscalIssueDate: formValues.notaFiscalIssueDate?.toISOString() ?? null,
    dueDate: formValues.dueDate?.toISOString() ?? null,
    labels: formValues.labels ?? [],
    classifications:
      formValues?.classifications ||
      classificationToFormValues(classificationsFromLabels, formValues?.labels),
  };
}

function parseToSubmitParams(params: SplitFormValues): SplitParams {
  return {
    update: params.update.map(parse),
    create: params.create.map(parse),
    delete: params.delete,
  };
}
