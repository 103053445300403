import type { Dispatch, SetStateAction } from 'react';

import { Stack } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { Typography } from 'src/design-system/components';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import BillsToPayDataGrid from 'src/features/bills-to-pay/BillsToPayDataGrid';
import { TotalSum } from 'src/features/bills-to-pay/BillsToPayDataGrid/GridToolbar';

import AutomaticPaymentTableFooter from './AutomaticPaymentTableFooter';

type Props = {
  automaticPayments: BillPayable[];
  selectedIds: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
};

export default function AutomaticPayment({
  automaticPayments,
  selectedIds,
  setSelectedIds,
}: Props) {
  const filteredCheckedPayments: BillPayable[] = automaticPayments.filter(
    ({ id }) => selectedIds.includes(id)
  );

  function onRowSelectionModelChange(newSelected: GridRowSelectionModel) {
    setSelectedIds(newSelected as string[]);
  }

  return (
    <Stack gap={2} height="330px">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack>
          <Typography fontWeight={700}>
            Selecionados para <u>inclusão automática</u> no(s) respectivo(s)
            banco(s):
          </Typography>

          <Typography variant="text-xs">
            Desmarque os que preferir incluir no banco de forma manual.
          </Typography>
        </Stack>

        <TotalSum
          bills={filteredCheckedPayments}
          description="Total a incluir automaticamente"
        />
      </Stack>

      <BillsToPayDataGrid
        bills={automaticPayments}
        GridToolBar={() => <></>}
        FooterSlots={AutomaticPaymentTableFooter}
        density="compact"
        RowSelection={{
          rowSelectionModel: selectedIds,
          onRowSelectionModelChange: onRowSelectionModelChange,
        }}
        pageSize={5}
        hideFooter={false}
      />
    </Stack>
  );
}
