import { useOpenPortal } from 'src/ui';

import type { FinancialStatementEntry } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { EntryType } from 'src/libs/finbits/Management/FinancialStatements/Entries/types';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import EditReceivableDrawer from 'src/features/receivables/EditReceivableDrawer/EditReceivableDrawer';
import EditFinancialEntryDrawer from 'src/features/entries/Drawers/FinancialEntry/EditFinancialEntryDrawer';
import EditBillDrawer from 'src/features/bills-to-pay/EditBillDrawer';
import EditBillModal from 'src/features/bills-to-pay/EditBillModal';

export default function useOpenEntryEditDrawer() {
  const openPortal = useOpenPortal();
  const { isEnabled: isMigrateModalEnabled } = useExternalFeatureFlag(
    Feature.MIGRATE_SCHEDULE_BILL_DRAWER_TO_MODAL
  );

  function openEntryEditDrawer(entry: FinancialStatementEntry) {
    if (entry.entryType === EntryType.FINANCIAL_ENTRY) {
      openPortal(EditFinancialEntryDrawer, {
        financialEntryId: entry.id,
        isSplit: !!entry.isSplit,
      });
    }

    if (entry.entryType === EntryType.BILL) {
      if (isMigrateModalEnabled) {
        openPortal(EditBillModal, {
          billId: entry.id,
        });

        return;
      }

      openPortal(EditBillDrawer, {
        billId: entry.id,
        getParams: { includingDeleted: true },
      });
    }

    if (entry.entryType === EntryType.RECEIVABLE) {
      openPortal(EditReceivableDrawer, { id: entry.id });
    }
  }

  return openEntryEditDrawer;
}
