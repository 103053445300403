import { useMutation, useQueryClient } from 'react-query';

import type { ApiError, ApiErrorForm } from 'src/libs/finbits/client';
import { authenticatedAPI } from 'src/libs/finbits/client';

import type { CreateSantanderConsentmentsParams } from './types';

async function connectSantanderConsentments({
  organizationId,
  companyId,
  ...params
}: CreateSantanderConsentmentsParams) {
  const response = await authenticatedAPI.post(
    `/organizations/${organizationId}/companies/${companyId}/santander_consentments`,
    params
  );

  return response.data;
}

export function useConnectSantanderConsentments() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation<
    CreateSantanderConsentmentsParams,
    ApiError<ApiErrorForm>,
    CreateSantanderConsentmentsParams
  >(connectSantanderConsentments, {
    onSuccess: ({ accountId }) => {
      queryClient.invalidateQueries({
        queryKey: ['openBankingConnections', { accountId }],
      });
    },
  });
  return { connectSantanderConsentments: mutate, ...rest };
}
