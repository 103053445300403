import type { Components } from '@mui/material';

export function createMuiMenu(): Components['MuiMenu'] {
  return {
    defaultProps: {
      onKeyDown: (event) => {
        if (event.key !== 'Escape') {
          event.stopPropagation();
        }
      },
    },
    styleOverrides: {
      paper: {
        borderRadius: '8px',
        paddingInline: '8px',
      },
      list: {
        padding: 0,
      },
    },
  };
}
