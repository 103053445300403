import { Button, Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';

import analytics from 'src/libs/analytics';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import BotAvatar from 'src/features/finbot/BotAvatar';

type Props = {
  handleClick: (userPrompt: string) => void;
};

const EXAMPLES = [
  'Qual foi o total de gastos nos últimos 3 meses e em quais categorias?',
  'Qual o maior pagamento feito no mês anterior?',
  'Forneça insights para melhorar a situação do meu caixa',
];

export default function EmptyState({ handleClick }: Props) {
  const { companyId } = useCompanyParams();
  function handleExampleClick(example: string) {
    analytics.track('FinBot Example Button Clicked', { company_id: companyId });
    handleClick(example);
  }

  return (
    <>
      <Stack alignItems="center" spacing={4}>
        <BotAvatar />

        <Typography fontWeight={600} align="center">
          Olá, sou a FinBot, sua assistente financeira.
        </Typography>
        <Typography align="center">
          Estou aqui para ajudar você a acessar e gerenciar rapidamente as
          informações da sua empresa, além de fornecer insights valiosos sobre
          sua operação.
        </Typography>
        <Typography align="center">
          Veja alguns exemplos de perguntas que consigo responder:
        </Typography>
      </Stack>
      <Stack alignItems="center" spacing={2}>
        {EXAMPLES.map((value, index) => (
          <Button
            key={index}
            variant="outlined"
            size="medium"
            onClick={() => handleExampleClick(value)}
          >
            {value}{' '}
          </Button>
        ))}
      </Stack>

      <Typography align="center">
        Ou, se preferir, faça a sua própria pergunta no campo abaixo.
      </Typography>
    </>
  );
}
