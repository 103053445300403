import { Fragment } from 'react';

import { Stack } from '@mui/material';
import { Typography } from 'src/design-system/components';
import { HelpCircleIcon } from 'src/mui/_icons';

import { Tooltip } from 'src/mui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';

import BillsToPayDataGrid from 'src/features/bills-to-pay/BillsToPayDataGrid';
import { TotalSum } from 'src/features/bills-to-pay/BillsToPayDataGrid/GridToolbar';

import styles from './ManualPayment.module.scss';

type Props = {
  manualPayments: BillPayable[];
};

export default function ManualPayment({ manualPayments }: Props) {
  return (
    <Stack gap={2} height="330px">
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography fontWeight={700}>
            Itens que você deve <u>incluir manualmente</u> no(s) respectivo(s)
            banco(s):
          </Typography>

          <Tooltip
            classes={{ tooltip: styles.tooltip }}
            placement="right"
            title={
              <Fragment>
                <Typography color={'grey.600'} fontWeight={600}>
                  Em quais casos um pagamento deverá ser incluído no banco de
                  forma manual?
                </Typography>

                <ul className={styles.tooltipList}>
                  <li>
                    Se a funcionalidade de pagamentos automáticos não estiver
                    disponível para o banco selecionado;
                  </li>
                  <li>
                    Se a conexão para pagamentos automáticos não tiver sido
                    ativada para o banco selecionado;
                  </li>
                  <li>Se o pagamento estiver em atraso;</li>
                  <li>
                    Se a data escolhida para pagamento de boleto não for um dia
                    útil;
                  </li>
                  <li>
                    Se o meio de pagamento escolhido não possibilitar o
                    pagamento automático;
                  </li>
                  <li>
                    Se o pagamento não possuir todas as informações necessárias;
                  </li>
                </ul>
              </Fragment>
            }
          >
            <HelpCircleIcon className={styles.icon} />
          </Tooltip>
        </Stack>

        <TotalSum
          bills={manualPayments}
          description="Total a incluir manualmente"
        />
      </Stack>

      <BillsToPayDataGrid
        bills={manualPayments}
        GridToolBar={() => <></>}
        density="compact"
        checkboxSelection={false}
        pageSize={5}
        hideFooter={false}
      />
    </Stack>
  );
}
