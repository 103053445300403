import type { Components } from '@mui/material';

export function createMuiSvgIcon(): Components['MuiSvgIcon'] {
  return {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.fontSize === 'xsmall' && {
          fontSize: '14px !important',
        }),
        ...(ownerState.fontSize === 'xxsmall' && {
          fontSize: '12px !important',
        }),
        ...(ownerState.fontSize === 'xxxsmall' && {
          fontSize: '8px !important',
        }),
      }),
    },
  };
}
