import { useState } from 'react';

import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { TrashIcon } from 'src/mui/_icons';
import { Typography } from 'src/design-system/components';

import { snackbar } from 'src/mui';

import { AvailableActions } from 'src/libs/finbits/Management/Entries/types';
import type { BillPayable } from 'src/libs/finbits/Bills/types';
import {
  useDeleteBill,
  useDeleteBillRecurrences,
} from 'src/libs/finbits/Bills';
import { canPerformAction } from 'src/libs/finbits/Management/FinancialStatements/Entries';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import styles from './DeleteButton.module.scss';

type Props = {
  bill?: BillPayable;
  onSuccess: () => void;
};

enum DeleteOccurrenceType {
  THIS = 'thisOccurrence',
  NEXT = 'thisAndNextOccurrences',
}

export default function DeleteButton({ bill, onSuccess }: Props) {
  const { companyId, organizationId } = useCompanyParams();
  const { deleteBill } = useDeleteBill();
  const { deleteBillRecurrences } = useDeleteBillRecurrences();

  const [radioValue, setRadioValue] = useState(DeleteOccurrenceType.THIS);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isRecurrent = Boolean(bill?.scheduledRecurrenceId);
  const isOpen = Boolean(anchorEl);

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClosePopover() {
    setAnchorEl(null);
  }

  function handleDelete() {
    if (!bill) return;

    deleteBill(
      {
        billId: bill.id,
        companyId,
        organizationId,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Pagamento excluído com sucesso!',
          });

          handleClosePopover();
          onSuccess();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao excluir pagamento!',
          });
        },
      }
    );
  }

  function handleDeleteRecurrences() {
    if (!bill) return;

    deleteBillRecurrences(
      {
        billId: bill.id,
        companyId,
        organizationId,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            message: 'Pagamentos excluídos com sucesso!',
          });

          handleClosePopover();
          onSuccess();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Falha ao excluir pagamentos!',
          });
        },
      }
    );
  }

  function handleConfirm() {
    if (radioValue === DeleteOccurrenceType.THIS) {
      handleDelete();

      return;
    }

    handleDeleteRecurrences();
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setRadioValue(event.target.value as DeleteOccurrenceType);
  }

  return (
    <WithAllowedPermission
      permissions={{ action: 'delete', resource: 'bills' }}
    >
      <>
        <Button
          variant="outlined"
          color="error"
          disabled={!canPerformAction(bill, AvailableActions.DELETE)}
          startIcon={<TrashIcon />}
          onClick={handleClick}
          aria-label="Excluir"
        >
          Excluir
        </Button>
        <Divider
          variant="fullWidth"
          flexItem
          orientation="vertical"
          className={styles.divider}
        />
        <Popover
          onClose={handleClosePopover}
          elevation={8}
          anchorEl={anchorEl}
          classes={{ paper: styles.popoverRoot }}
          open={isOpen}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: isRecurrent ? 285 : 205,
            horizontal: 'right',
          }}
        >
          <Typography color="grey.900" fontWeight={600} variant="text-lg">
            {isRecurrent
              ? 'Excluir lançamento recorrente'
              : 'Tem certeza que deseja excluir?'}
          </Typography>
          <Typography color="grey.600" variant="text-sm" marginTop={2}>
            Este item será movido para a lista de lançamentos excluídos. Você
            poderá restaurá-lo, se precisa.
          </Typography>

          {isRecurrent && (
            <FormControl classes={{ root: styles.radioControl }}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radioValue}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={DeleteOccurrenceType.THIS}
                  control={
                    <Radio size="small" classes={{ root: styles.radio }} />
                  }
                  label="Apenas esta ocorrência"
                  className={styles.radioLabel}
                />
                <FormControlLabel
                  value={DeleteOccurrenceType.NEXT}
                  control={
                    <Radio size="small" classes={{ root: styles.radio }} />
                  }
                  label="Esta e as ocorrências seguintes"
                  className={styles.radioLabel}
                />
              </RadioGroup>
            </FormControl>
          )}

          <Stack
            marginTop={6}
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
          >
            <Button
              variant="outlined"
              onClick={handleClosePopover}
              className={styles.button}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="error"
              className={styles.button}
              onClick={handleConfirm}
            >
              {isRecurrent ? 'Confirmar exclusão' : 'Sim, quero excluir'}
            </Button>
          </Stack>
        </Popover>
      </>
    </WithAllowedPermission>
  );
}
