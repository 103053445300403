import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { dateColumn } from 'src/mui/_scss';

import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

export function notaFiscalIssueDateColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return dateColumn({
    columnProps: {
      aggregable: false,
      editable: true,
      headerName: 'Data de emissão',
      field: 'notaFiscalIssueDate',
      minWidth: 110,
      flex: 2,
      ...columnProps,
    },
  });
}
