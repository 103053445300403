import { snackbar } from 'src/mui';

import { useOpenPortal } from 'src/ui';

import type { BillPayable } from 'src/libs/finbits/Bills/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useCreateFinancialFromBill } from 'src/libs/finbits/Bills';

import type { ManualConciliateFormProps } from 'src/features/entries/ManualConciliateHeaderButton/ManualConciliateModal';
import ManualConciliateModal from 'src/features/entries/ManualConciliateHeaderButton/ManualConciliateModal';

type Props = {
  bill: BillPayable;
  onSuccess?: () => void;
};

export default function useMarkAsFinancialAction({ bill, onSuccess }: Props) {
  const openPortal = useOpenPortal();

  const { companyId, organizationId } = useCompanyParams();

  const { createFinancialFromBill, isLoading: isMarkingAsFinancial } =
    useCreateFinancialFromBill();

  function handleManualConciliate(params: ManualConciliateFormProps) {
    createFinancialFromBill(
      {
        companyId,
        organizationId,
        billId: bill?.id,
        ...params,
      },
      {
        onSuccess: () => {
          onSuccess && onSuccess();

          snackbar({
            variant: 'success',
            message: 'O lançamento programado foi realizado com sucesso!',
          });
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message:
              'Ocorreu um erro ao marcar o lançamento programado como realizado!',
          });
        },
      }
    );
  }

  function markAsFinancial() {
    openPortal(ManualConciliateModal, {
      handleOnSubmit: handleManualConciliate,
      initialBalanceDate: bill.account?.initialBalance?.date,
      isLoading: isMarkingAsFinancial,
    });
  }

  return {
    markAsFinancial,
    isMarkingAsFinancial,
  };
}
