import type { AutocompleteProps } from '@mui/material';
import { Autocomplete, InputBase } from '@mui/material';
import classNames from 'classnames';

import styles from './SingleSelect.module.scss';

export default function SingleSelect<T extends { id: string; name: string }>({
  options = [],
  bordered = true,
  ...props
}: AutocompleteProps<T, false, false, false> & { bordered?: boolean }) {
  const className = classNames(styles.select, {
    [styles.bordered]: bordered,
  });

  return (
    <Autocomplete<T>
      {...props}
      className={className}
      autoHighlight
      openOnFocus
      fullWidth
      options={options}
      getOptionLabel={(option) => option?.name ?? option}
      isOptionEqualToValue={(opt, selected) => opt?.id === selected?.id}
      renderInput={(params) => (
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus
          fullWidth
          className={styles.inputBase}
        />
      )}
    />
  );
}
