import type {
  GridColDef,
  GridGroupingValueGetter,
  GridValidRowModel,
  GridValueFormatter,
  GridValueOptionsParams,
} from '@mui/x-data-grid-premium';
import { singleSelectColumn } from 'src/mui/_scss';

import type { Category } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';

import CategoriesAutocomplete from 'src/features/entries/EntriesDataGrid/columns/categoryColumn/CategoriesAutocomplete';
import type { ColumnProps } from 'src/features/entries/EntriesDataGrid/columns/types';

export function categoryColumn<T extends GridValidRowModel>(
  columnProps?: ColumnProps<T>
): GridColDef<T> {
  return singleSelectColumn({
    columnProps: {
      minWidth: 100,
      flex: 2,
      editable: true,
      aggregable: false,
      headerName: 'Categoria',
      valueFormatter: (value: GridValueFormatter<Category>) => {
        return value?.name;
      },
      groupingValueGetter: (value: GridGroupingValueGetter<T>) => {
        return value?.name ?? 'sem categoria';
      },
      getOptionValue: (value: any) => value?.id,
      getOptionLabel: (value: any) => value?.name,
      sortComparator: (catA, catB) => {
        const nameA = catA?.name ?? '';
        const nameB = catB?.name ?? '';

        return nameA.localeCompare(nameB);
      },
      valueOptions: (params: GridValueOptionsParams<T>) => {
        return [params?.row?.category];
      },
      ...columnProps,
      field: 'category',
    },
    SelectComponent: CategoriesAutocomplete,
  });
}
