import { useRef, useState } from 'react';

import type { TransitionProps } from '@mui/material/transitions';
import { Button, DialogContent, Slide } from '@mui/material';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';

import { Dialog } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { useSetupNotaFiscal } from 'src/libs/finbits/NotaFiscal/Setup';
import type { SetupNotaFiscalFormParams } from 'src/libs/finbits/NotaFiscal/Setup/types';

import { useOpenConfirmLeaveFormDialog } from 'src/features/entries/Dialogs';
import type { NotaFiscalFormRef } from 'src/features/nota-fiscal/NotaFiscalSetupForm/NotaFiscalSetupForm';
import NotaFiscalSetupForm from 'src/features/nota-fiscal/NotaFiscalSetupForm/NotaFiscalSetupForm';
import AdditionalDataFields from 'src/features/nota-fiscal/NotaFiscalSetupForm/AdditionalDataFields';

import NotaFiscalSetupTabs, {
  NotaFiscalSetupTab,
} from './NotaFiscalSetupTabs/NotaFiscalSetupTabs';
import style from './EditNotaFiscalSetupModal.module.scss';

export default function EditNotaFiscalSetupModal({
  onClose,
  open = true,
  onExit,
}: PortalProps) {
  const openLeaveDialog = useOpenConfirmLeaveFormDialog();

  const [selectedTab, setSelectedTab] = useState(
    NotaFiscalSetupTab.ADDITIONAL_DATA
  );
  const { isLoading } = useSetupNotaFiscal();

  const formRef = useRef<NotaFiscalFormRef>(null);

  function handleClose() {
    if (formRef.current?.isDirty) {
      return openLeaveDialog({
        onConfirm: onClose,
      });
    }

    onClose();
  }

  function handleEditNotaFiscalSetup(_params: SetupNotaFiscalFormParams) {
    //TODO: Create function as soon as backend new route is created
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: 'up',
          onExited: onExit,
        } as TransitionProps
      }
    >
      <Dialog.Header
        size="small"
        title="Configurar Notas Fiscais"
        onClose={handleClose}
      />

      <DialogContent dividers className={style.dialogContent}>
        <TabContext value={selectedTab}>
          <NotaFiscalSetupTabs onChange={setSelectedTab} />
          <NotaFiscalSetupForm
            ref={formRef}
            onSubmit={handleEditNotaFiscalSetup}
          >
            <TabPanel
              value={NotaFiscalSetupTab.ADDITIONAL_DATA}
              aria-label="Dados Complementares"
              className={style.tabPanel}
            >
              <AdditionalDataFields />
            </TabPanel>
            <TabPanel
              value={NotaFiscalSetupTab.SERVICES}
              aria-label="Serviços"
              className={style.tabPanel}
            >
              <h1>Aba de Serviços</h1>
            </TabPanel>
            <TabPanel
              value={NotaFiscalSetupTab.DIGITAL_CERTIFICATE}
              aria-label="Certificado Digital"
              className={style.tabPanel}
            >
              <h1>Aba de Certificado Digital</h1>
            </TabPanel>
          </NotaFiscalSetupForm>
        </TabContext>
      </DialogContent>

      <Dialog.Actions size="small">
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton
          loading={isLoading}
          type="submit"
          form="nota-fiscal-form"
          variant="contained"
          color="primary"
        >
          Salvar
        </LoadingButton>
      </Dialog.Actions>
    </Dialog>
  );
}
