import { Col, Form, Input, Space, Tag } from 'antd';

import { FormItem, MaskedInput } from 'src/ui';

import type { PaymentDetails } from 'src/libs/finbits/Payments/types';
import { PaymentMethod } from 'src/libs/finbits/PaymentMethods/types';
import {
  cnpjMask,
  cpfMask,
  documentValidator,
} from 'src/libs/finbits/Documents';
import type { ContactType } from 'src/libs/finbits/Organization/Companies/Contacts/enum';

import PaymentMethodSelect from 'src/features/payments/DEPRECATED_PaymentMethodSelect';
import PixFields from 'src/features/payments/PixFields';
import BankFields from 'src/features/payments/BankFields';
import PaymentDetailsField from 'src/features/payments/PaymentDetailsField';
import BoletoField from 'src/features/payments/BoletoField';
import PixQrCodeField from 'src/features/payments/PixQrCodeField';

type Props = {
  requiredFields?: Array<keyof PaymentDetails> | string[];
  disabled?: boolean;
  paymentMethodSelectUIspan?: number;
  pixSearchEnabled?: boolean;
  contactParams?: {
    contactType?: ContactType | null;
    contactDocument?: string | null;
  };
  boletoSearchEnabled?: boolean;
  suggestions?: PaymentDetails;
};

export default function PaymentFields({
  requiredFields,
  disabled = false,
  paymentMethodSelectUIspan = 24,
  contactParams,
  boletoSearchEnabled = false,
  suggestions,
}: Props) {
  const paymentMethod = Form.useWatch(['paymentDetails', 'paymentMethod']);

  const isPix = paymentMethod === PaymentMethod.PIX;
  const isWireTransfer = paymentMethod === PaymentMethod.WIRE_TRANSFER;
  const isBoleto = paymentMethod === PaymentMethod.BOLETO;
  const isPixQrCode = paymentMethod === PaymentMethod.PIX_QR_CODE;

  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={paymentMethodSelectUIspan}
        lg={paymentMethodSelectUIspan}
      >
        <FormItem
          name={['paymentDetails', 'paymentMethod']}
          label="Meio de pagamento"
        >
          <PaymentMethodSelect disabled={disabled} />
        </FormItem>
      </Col>

      {isPix && (
        <PixFields
          baseFieldName="paymentDetails"
          disabled={disabled}
          requiredFields={requiredFields}
          contactParams={contactParams}
          isSuggestion={Boolean(suggestions?.pixKey)}
        />
      )}

      {isWireTransfer && (
        <>
          <Col xs={24} sm={24} md={12} lg={12}>
            <FormItem
              name={['paymentDetails', 'accountDocument']}
              label={
                <Space>
                  Documento
                  {suggestions?.accountDocument && (
                    <Tag color="blue">Preenchimento sugerido</Tag>
                  )}
                </Space>
              }
              rules={[{ validator: documentValidator }]}
              required={requiredFields?.includes('accountDocument')}
            >
              <MaskedInput
                mask={[cpfMask, cnpjMask]}
                size="large"
                placeholder="Digite o CPF/CNPJ"
                disabled={disabled}
              />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <FormItem
              name={['paymentDetails', 'payeeName']}
              required={requiredFields?.includes('payeeName')}
              label={
                <Space>
                  Recebedor
                  {suggestions?.payeeName && (
                    <Tag color="blue">Preenchimento sugerido</Tag>
                  )}
                </Space>
              }
            >
              <Input
                size="large"
                disabled={disabled}
                placeholder="Digite o nome"
              />
            </FormItem>
          </Col>
          <BankFields
            baseFieldName="paymentDetails"
            disabled={disabled}
            suggestions={suggestions}
            requiredFields={requiredFields}
          />
        </>
      )}

      {isBoleto && (
        <BoletoField
          disabled={disabled}
          fieldName={['paymentDetails', 'boleto', 'digitableLine']}
          requiredFields={requiredFields}
          boletoSearchEnabled={boletoSearchEnabled}
        />
      )}

      {isPixQrCode && (
        <PixQrCodeField
          disabled={disabled}
          fieldName={['paymentDetails', 'pixKey']}
          requiredFields={requiredFields}
        />
      )}

      {!isPix &&
        !isWireTransfer &&
        !isBoleto &&
        !isPixQrCode &&
        paymentMethod && (
          <PaymentDetailsField
            disabled={disabled}
            fieldName={['paymentDetails', 'paymentDetails']}
          />
        )}
    </>
  );
}
