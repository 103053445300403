import type { Components } from '@mui/material';

export function createMuiAlertTitle(): Components['MuiAlertTitle'] {
  return {
    styleOverrides: {
      root: {
        fontWeight: 600,
      },
    },
  };
}
