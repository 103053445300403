import { useMemo, useState } from 'react';

import type { GridApi } from '@mui/x-data-grid-premium';
import _debounce from 'lodash/debounce';

import { SearchInput } from 'src/mui';

import styles from './SearchField.module.scss';

type Props = {
  apiRef: GridApi;
};

export default function SearchField({ apiRef }: Props) {
  const [searching, setSearching] = useState(false);

  const quickFilterWithDebounce = useMemo(
    () =>
      _debounce((value) => {
        apiRef.setQuickFilterValues(value.trim().split(' '));
        setSearching(false);
      }, 350),

    [apiRef]
  );

  function handleChangeSearch(event: any) {
    setSearching(true);
    quickFilterWithDebounce(event);
  }

  return (
    <SearchInput
      className={styles.searchInput}
      onChange={handleChangeSearch}
      loading={searching}
    />
  );
}
