import type { CSSProperties, Ref } from 'react';
import { forwardRef, useRef } from 'react';

import { Stack } from '@mui/material';
import type { GridApi } from '@mui/x-data-grid-premium';
import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import type { QuickActions, TableRootActions } from 'src/mui/_scss/Table/types';

import SearchField from '../SearchField';

import styles from './ToolbarQuickActions.module.scss';

type Props = {
  apiRef: GridApi;
  QuickActions?: QuickActions;
  showQuickFilter?: boolean;
  setCurrentButtonRef: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  style?: CSSProperties;
  TableRootActions?: TableRootActions;
};

export default forwardRef(function ToolbarQuickActions(
  {
    apiRef,
    QuickActions,
    showQuickFilter,
    setCurrentButtonRef,
    style,
    TableRootActions,
  }: Props,
  ref: Ref<HTMLDivElement>
) {
  const filterButtonEl = useRef(null);
  const columnButtonEl = useRef(null);

  return (
    <div ref={ref} style={style}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.stack}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          flexGrow={1}
          spacing={2}
        >
          {showQuickFilter && <SearchField apiRef={apiRef} />}

          <GridToolbarFilterButton
            slotProps={{
              button: {
                size: 'medium',
                onMouseDown: () => {
                  setCurrentButtonRef(filterButtonEl.current);
                },
              },
            }}
            ref={filterButtonEl}
          />

          {QuickActions && <QuickActions />}

          <GridToolbarColumnsButton
            slotProps={{
              button: {
                variant: 'text',
                size: 'medium',
                onMouseDown: () => {
                  setCurrentButtonRef(columnButtonEl.current);
                },
              },
            }}
            ref={columnButtonEl}
          />
        </Stack>

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {TableRootActions && <TableRootActions apiRef={apiRef} />}
        </Stack>
      </Stack>
    </div>
  );
});
