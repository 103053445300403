import { SparklesIcon } from 'src/mui/_icons';

import { Tooltip } from 'src/mui';

import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import type { SuggestedFields } from 'src/features/EntryForm/types';

import { useEntrySuggestionsContext } from '../EntrySuggestionsProvider';

import styles from './SuggestionIcon.module.scss';

type Props = {
  fieldName: SuggestedFields;
};

export default function SuggestionIcon({ fieldName }: Props) {
  const { isSuggestionField } = useEntrySuggestionsContext();
  const { isEnabled: isSparkleForFieldSuggestionFromAiEnabled } =
    useExternalFeatureFlag(Feature.SPARKLE_FOR_FIELD_SUGGESTION_FROM_AI);

  if (
    isSuggestionField(fieldName) &&
    isSparkleForFieldSuggestionFromAiEnabled
  ) {
    return (
      <Tooltip
        className={styles.icon}
        title={
          <>
            Campo autopreenchido pela FinBot. <br />
            Por favor, revise e ajuste, se necessário.
          </>
        }
      >
        <SparklesIcon
          fontSize="xsmall"
          aria-label="Autopreenchimento pela FinBot"
        />
      </Tooltip>
    );
  }

  return null;
}
