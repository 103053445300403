import type { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';

import { floatToDecimal, toFloat, toInteger } from 'src/libs/finbits/Money';
import { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';

import {
  emptyFilter,
  equalFilter,
  greaterThanFilter,
  greaterThanOrEqualFilter,
  lessThanFilter,
  lessThanOrEqualFilter,
  notEmptyFilter,
  notEqualFilter,
} from './filters';
import AmountInput from './AmountInput';

type Props<T extends GridValidRowModel> = {
  columnProps: GridColDef<T>;
  type?: BalanceType;
};

export function amountColumn<T extends GridValidRowModel>({
  columnProps,
  type = BalanceType.DEBIT,
}: Props<T>): GridColDef<T> {
  return {
    filterOperators: [
      equalFilter,
      notEqualFilter,
      greaterThanFilter,
      greaterThanOrEqualFilter,
      lessThanFilter,
      lessThanOrEqualFilter,
      emptyFilter,
      notEmptyFilter,
    ],
    type: 'number',
    valueGetter: (value?: number) => {
      if (!value) return null;

      return toFloat(value, type);
    },
    valueFormatter: (value: number | undefined) => {
      return value && floatToDecimal(value);
    },
    groupingValueGetter: (value: number | undefined) => {
      if (!value) return null;

      return floatToDecimal(value);
    },
    getApplyQuickFilterFn: (value: string) => {
      if (!value) return null;

      return (params: number): boolean => {
        if (!params) return false;

        return value.split(' ').some((splitFilter) => {
          return floatToDecimal(params)?.includes(splitFilter);
        });
      };
    },
    valueSetter: (value: number, row) => {
      const parsedValue = Math.abs(toInteger(value));

      return {
        ...row,
        [columnProps.field]: parsedValue,
        updated: { [columnProps.field]: parsedValue },
      };
    },
    renderEditCell: (props) => <AmountInput {...props} />,
    ...columnProps,
  };
}
