import type { ReactNode } from 'react';

import { InputAdornment } from '@mui/material';

import type { SuggestedFields } from 'src/features/EntryForm/types';

import SuggestionIcon from '../SuggestionIcon';

import styles from './InputAdornmentSuggestion.module.scss';

type Props = {
  fieldName: SuggestedFields;
  children?: ReactNode;
};

export default function InputAdornmentSuggestion({
  fieldName,
  children,
}: Props) {
  return (
    <InputAdornment position="end" className={styles.iconBox}>
      <SuggestionIcon fieldName={fieldName} />
      {children && <span className={styles.openIcon}>{children}</span>}
    </InputAdornment>
  );
}
