import { Button } from '@mui/material';
import { Alert, AlertTitle } from 'src/design-system/components';
import { LightedBulbIcon } from 'src/mui/_icons';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import styles from './BankAutomationAlert.module.scss';

export default function BankAutomationAlert() {
  const { companyId, organizationId } = useCompanyParams();

  const urlAccountSettings = generateCompanyPath(RouteKey.ACCOUNTS, {
    companyId,
    organizationId,
  });

  return (
    <Alert
      severity="info"
      icon={<LightedBulbIcon fontSize="inherit" />}
      classes={{
        action: styles.alertHasButton,
        root: styles.alert,
      }}
      action={
        <Button
          href={urlAccountSettings}
          target="_blank"
          variant="text"
          color="primary"
        >
          Conecte já a sua conta
        </Button>
      }
    >
      <AlertTitle>
        Automatize transferências (TED), pagamentos de boletos e Pix.
      </AlertTitle>
      Disponível para contas dos bancos BTG e Santander.
    </Alert>
  );
}
