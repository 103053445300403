import { forwardRef, useState } from 'react';

import type { InputBaseComponentProps } from '@mui/material';
import type { AnyMaskedOptions, InputMask } from 'imask';
import InputBaseMasked from 'src/mui/InputBase/InputBaseMasked';

import { floatToDecimal, toFloatRepresentation } from 'src/libs/finbits/Money';

type Props = Omit<InputBaseComponentProps, 'onChange'> & {
  onChange?: (value: number, e?: InputEvent) => void;
  signed?: boolean;
};

const InputBaseTableAmount = forwardRef<HTMLInputElement, Props>(
  ({ onChange, value, signed = false, ...props }, ref) => {
    const [internalValue, setInternalValue] = useState(
      value ? floatToDecimal(value) : undefined
    );

    function handleChange(
      value: string,
      _mask: InputMask<AnyMaskedOptions>,
      event: InputEvent | undefined
    ) {
      setInternalValue(value);

      if (!!event && !!onChange) {
        onChange(toFloatRepresentation(value), event);
      }
    }

    return (
      <InputBaseMasked
        inputRef={ref}
        padFractionalZeros
        onAccept={handleChange}
        mask={Number}
        thousandsSeparator="."
        radix=","
        mapToRadix={['']}
        signed={signed}
        scale={2}
        value={internalValue}
        componentsProps={{
          inputBase: {
            ...props,
            inputProps: {
              ...props.inputProps,
              maxLength: 20,
            },
          },
        }}
      />
    );
  }
);

export default InputBaseTableAmount;
